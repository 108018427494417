import API_CONST from '@/constants/ApiConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

@Component({
  components: {
    'vue-dropzone': vue2Dropzone
  }
})
export default class Dropzone extends Vue {
  private url = '';
  private fileType = ['image/png', 'image/jpg', 'image/jpeg'];
  private loadedImg = { uploaded:false, error: false, errorMsg: `We haved trouble uploading your photo. Please ensure that it is either JPG or PNG, and
  does not exceed 1MB in size. Try again`};
  private dltConfirmation = false;
    $refs!: {
        myVueDropzone: any;
        fileUpload: any;
    };

    @Prop({default:() => {
      return {
        method: 'POST',
        url: `${process.env.VUE_APP_BASE_URL}${API_CONST.UPLOAD_CSV}?siteId=`+1,
        headers: {
          'Accept' : '*/*',
          'Authorization': `Bearer ${APP_UTILITIES.getCookie('auth_token')}`
        },
        maxFilesize: 1,
        maxFiles: 1,
        includeStyling: false,
        chunking: false,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg,image/jpg,image/png',
        autoProcessQueue: false,
        clickable: true,
        previewsContainer: false,
        thumbnailWidth: 250,
        thumbnailHeight: 140,
        parallelUploads: 1,
      };
    }}) 
      dropzoneOptions !: any;

    private chooseFiles() {
      this.$refs.fileUpload.click();
    }

    private onFilePicked(event: any) {
      const files = event.target.files;
      if(this.examineFile(files[0])) {
        const fileReader = new FileReader();
        let imageUrl: string | ArrayBuffer | null = '';
        fileReader.addEventListener('loadend', () => {
          imageUrl = fileReader.result;
          this.$refs.myVueDropzone.removeAllFiles();
          this.$refs.myVueDropzone.manuallyAddFile(files[0], imageUrl);
        });
        fileReader.readAsDataURL(files[0]);
      }
    }

    private fileAdded(file: any) {
      const files = this.$refs.myVueDropzone.getAcceptedFiles();
      const validFile = this.examineFile(file);
      if(!files.length && validFile) {
        this.loadedImg.uploaded = true;
        this.loadedImg.error = false;
        this.url = URL.createObjectURL(file);
      } 
    }

    private manuallyAdded(file: any) {
      this.url = URL.createObjectURL(file);
    }
     
    private fileRemoved(file:any, error: any, xhr: any) {

    }

    private saveImg() {
      this.$refs.myVueDropzone.processQueue();
    }

    private success(file: any, response: any) {

    }

    private examineFile(file: any) {
      if(!this.fileType.includes(file.type) || file.size > 1000000) {
        this.loadedImg.error = true;
        return false;
      }
      else {
        this.loadedImg.error = false;
        return true;
      }
    }

    private fileExceeded(file: any) {
      this.loadedImg.error = true;
    }

    private error(file: any, message: any, xhr: any) {
      this.loadedImg.error = true;
    }

    private completed() {
  
    }

    removeAllFiles() {
      this.url = '';
      this.dltConfirmation = false;
      this.loadedImg.uploaded = false;
      this.loadedImg.error = false;
      this.$refs.myVueDropzone.removeAllFiles();
    }

}
